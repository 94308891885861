import React from "react";
import { Button } from "./Webinar.tsx";

const SuccessPage = () => {
  // Extracting URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const webinarLink = urlParams.get("wj_lead_unique_link_live_room");
  const eventDate = urlParams.get("wj_next_event_date");
  const eventTime = urlParams.get("wj_next_event_time");
  const eventTimezone = urlParams.get("wj_next_event_timezone");

  return (
    <div className="min-h-screen bg-black text-green-500 flex flex-col items-center p-4 md:p-8">
      {/* Logo */}
      <img src="/D.png" alt="Founder" className="w-1/3 md:w-1/6 rounded-lg mb-4" />

      {/* Headline */}
      <h2 className="mt-4 text-center text-white text-2xl md:text-4xl font-bold">
        Your Spot Is Confirmed - Complete The Steps Below To Come Prepared To The Workshop
      </h2>

      {/* Step 1: Discord Access */}
      <div className="mt-6 w-full md:w-1/2 bg-black p-6 rounded-lg text-center border border-green-500 shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold">Step 1: Claim Your Exclusive Bonus - Join Our Discord</h2>
        <p className="text-white mt-2">Click the button below to join our free Discord community:</p>
        <a
          href="https://discord.gg/trendtradersacademy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="mt-4 w-full md:w-auto">Join Discord Now</Button>
        </a>
      </div>

      {/* Step 2: Webinar Link */}
      <div className="mt-10 w-full md:w-1/2 bg-black p-6 rounded-lg text-center border border-green-500 shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold">Step 2: Save Your Link To Join The Class</h2>
        <p className="text-white mt-2">The webinar is scheduled for:</p>
        <p className="text-lg font-semibold">{eventDate} at {eventTime} {eventTimezone}</p>
        <p className="text-white mt-2">Click the button below to join the live webinar:</p>
        <p className="break-all">{webinarLink}</p>
        <a
          href={webinarLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button className="mt-4 w-full md:w-auto">Join Webinar Now</Button>
        </a>
        <p className="text-sm text-white mt-2">You will also be receiving the link in your email and we will text you the link when it's time.</p>
      </div>

      {/* Step 3: Class Checklist */}
      <div className="mt-10 w-full md:w-1/2 bg-black p-6 rounded-lg text-left border border-green-500 shadow-lg">
        <h2 className="text-xl md:text-2xl font-bold text-center">Step 3: Read Your Class Checklist</h2>
        <ul className="mt-4 space-y-2 text-md md:text-lg text-white">
          <li>✔ Watch on a desktop or laptop (NOT a phone). Your phone screen won't allow for the best experience or allow you to take part in the chat.</li>
          <li>✔ Set aside at least 90 minutes for the class and block off your calendar so you don't have any distractions. Make sure to close all of your other applications when we're live.</li>
          <li>✔ Engage on the class LIVE! Attention = Retention. The more you engage, the more you'll get out of the class.</li>
        </ul>
      </div>
    </div>
  );
};

export default SuccessPage;
