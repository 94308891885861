import React from "react";
import WidgetBot from '@widgetbot/react-embed';

// Button Component
export const Button = ({ className = "", ...props }) => (
  <button
    className={`px-8 py-4 bg-green-500 text-black text-lg font-semibold rounded-lg hover:bg-green-600 transition ${className}`}
    {...props}
  />
);

// Card Component
export const Card = ({ className = "", children, ...props }) => (
  <div className={`bg-black p-4 rounded-lg shadow-md ${className}`} {...props}>
    {children}
  </div>
);

// CardContent Component
export const CardContent = ({ className = "", children, ...props }) => (
  <div className={`p-2 ${className}`} {...props}>
    {children}
  </div>
);

const HomePage = () => {
  return (
    <div className="min-h-screen bg-black text-green-500 flex flex-col items-center p-4 md:p-8">
      {/* Logo */}
      <img src="/D.png" alt="Founder" className="w-2/5 md:w-1/5 rounded-lg mb-4" />

      {/* Headline */}
      <h2 className="mt-4 text-center text-2xl md:text-4xl text-white font-semibold">
        A Simple and <span className="text-green-500">Predictable Way</span> <br /> To Generate <span className="text-green-500">$10K+/Month</span> Trading Supply and Demand
      </h2>

      {/* Features List */}
      <div className="my-8 text-md text-center max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 border border-green-500 py-4 px-4 md:px-8 items-center rounded-md">
        <ul className="space-y-4 text-left">
          <li className="flex items-center">✔ 4 Hours Of In-Depth Core Strategy Training</li>
          <li className="flex items-center">✔ Supply & Demand Qualification Blueprint</li>
          <li className="flex items-center">✔ Trend Box Analysis Technique</li>
        </ul>
        <ul className="space-y-4 text-left">
          <li className="flex items-center">✔ 2 Trend Traders Academy Trading Models</li>
          <li className="flex items-center">✔ Private Trading Community Access</li>
          <li className="flex items-center">✔ Exclusive Bonuses & Much More</li>
        </ul>
      </div>

      <a href="https://collectcheckout.com/r/3qrof9ew1da6obkwba6olt3zu5bbb3"><Button className="mt-4 w-full max-w-sm">Join the Academy</Button></a>

      {/* Founder Section */}
      <div className="mt-10 flex flex-col md:flex-row max-w-3xl items-center border border-green-500 rounded-md p-4">
        <img src="/founder.jpg" alt="Founder" className="w-full md:w-2/5 rounded-lg mb-4 md:mb-0 md:mr-7" />
        <div>
          <h3 className="text-2xl font-bold">Trend Traders Academy Founder - Mathews Farias</h3>
          <p className="mt-2 text-md text-white">
            At just 13 years old, I began learning from three Wall Street veterans who taught me something crucial...
            We will never beat the institutions, so we must learn to follow them through identifying the areas institutional buying & selling occurred.
            I've refined this into 2 trading models that work so consistently, my students are getting consistent prop firm payouts in as little as 4 months.
          </p>
        </div>
      </div>

      {/* Testimonials */}
      <div className="mt-10 w-full">
        <h3 className="text-2xl font-bold underline mb-2 text-center">Live Testimonials</h3>
        <div className="rounded-lg w-full flex justify-center">
          <WidgetBot className="w-full md:w-1/2 h-[400px] md:h-[600px]"
    server="1247352959554158732"
    channel="1326545885315727390"
          />
        </div>
      </div>

      <a href="https://collectcheckout.com/r/3qrof9ew1da6obkwba6olt3zu5bbb3"><Button className="mt-4 w-full max-w-sm">Join the Academy</Button></a>

      {/* Bonuses */}
      <div className="mt-10 w-full max-w-4xl text-center border border-green-500 px-4 py-2 rounded-md">
        <h3 className="text-3xl font-bold underline">Exclusive Bonuses</h3>
        <ul className="mt-4 space-y-2 text-lg">
          <li>✔ 1-Hour Live Group Q&A Call (Advanced trade analysis & risk management)</li>
          <li>✔ 3-Hour Advanced Strategy Recordings (Real-time trade setups & analysis)</li>
          <li>✔ 3 Detailed Trade Breakdowns</li>
        </ul>
      </div>
    <div className="mt-10 grid grid-cols-3 gap-4 max-w-5xl">
        <img src="/image/cert1.jpg" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert2.png" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert3.png" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert4.jpg" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert5.png" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert6.png" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert7.jpg" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert8.png" alt="Founder" className="w-full rounded-lg" />
        <img src="/image/cert9.png" alt="Founder" className="w-full rounded-lg" />
      </div>
      {/* Payment Portal */}
      <div className="mt-10 w-full max-w-md bg-black p-6 rounded-lg text-center border border-green-500">
        <h2 className="text-xl font-bold">Join Now!</h2>
        <p className="mt-2">Get instant access to all the training and bonuses.</p>
        <a href="https://collectcheckout.com/r/3qrof9ew1da6obkwba6olt3zu5bbb3"><Button className="mt-4 w-full">Join the Academy</Button></a>
      </div>

      {/* FAQ */}
      <div className="mt-10 w-full max-w-3xl select-none">
        <h3 className="text-2xl font-bold">Frequently Asked Questions</h3>
        <div className="mt-4 space-y-4">
          {[...Array(6)].map((_, index) => (
            <details key={index} className="bg-black p-4 rounded-lg border border-green-500">
              <summary className="font-semibold">{["What is Trend Traders Academy?", "Is this good for beginners?", "How much time do I need to commit?", "What makes your system different from other trading programs?", "How long will it take to start seeing results?", "Can I do this if I'm working a job or in school?"][index]}</summary>
              <p className="mt-2 text-sm">{["Trend Traders Academy is a comprehensive trading education program where you'll learn our proprietary supply and demand trading strategy. It includes 4 hours of in-depth training, a complete trading system, and live support to help you become a consistently profitable trader.",
                  "Absolutely. We've designed the program to take you from complete beginner to funded trader. We start with market fundamentals and build up to advanced concepts step by step.",
                  "The core strategy is 4 hours, which you can complete at your own pace. We recommend setting aside 2-3 hours per week for studying the material and practicing the concepts.",
                  "We focus purely on supply and demand trading, our strategy is proven through years of live trading results, and you get personal trade reviews and critiques of your supply & demand zones.",
                   "Results vary, but some students have achieved success in as little as 180 days.",
                   "Yes. All you need is an internet connection, a computer, and 3-4 hours per day."][index]}</p>
            </details>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
