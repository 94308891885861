import './App.css';
import LandingPage from './LandingPage.js';
import HomePage from './Page.tsx';
import Webinar from './Webinar.tsx';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SuccessPage from "./Success.tsx";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/success" element={<SuccessPage />} />
      </Routes>
    </Router>
  );
};

export default App;
