import { useEffect } from "react";

const WistiaPlayer = () => {
  useEffect(() => {
    // Load Wistia scripts dynamically
    const script1 = document.createElement("script");
    script1.src = "https://fast.wistia.com/player.js";
    script1.async = true;
    document.body.appendChild(script1);

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/embed/77hwvr9w02.js";
    script2.async = true;
    script2.type = "module";
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);

  return (
    <div className="wistia-container">
      <style>{`
        wistia-player[media-id='77hwvr9w02']:not(:defined) {
          background: center / contain no-repeat url('https://fast.wistia.com/embed/medias/77hwvr9w02/swatch');
          display: block;
          filter: blur(5px);
          padding-top: 56.25%;
        }

        .wistia-container {
          position: relative;
          width: 100%;
          max-width: 640px;
          margin: auto;
        }

        wistia-player {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
        }
      `}</style>
      <wistia-player media-id="77hwvr9w02" aspect="1.7777777777777777"></wistia-player>
    </div>
  );
};

export default WistiaPlayer;
