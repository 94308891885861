import { useEffect } from "react";

const WebinarJamButton = () => {
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://event.webinarjam.com/register/${code}/embed-button?formTemplate=2&formColor=1`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex justify-center">
      <button
        type="button"
        className="wj-embed-button border-2 border-black bg-green-500 text-black text-lg px-20 py-4 rounded-md font-semibold mt-4 leading-snug cursor-pointer shadow-none"
        data-webinarHash={code}
      >
        Register Now!
      </button>
    </div>
  );
};

export default WebinarJamButton;