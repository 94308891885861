import React, { useState, useEffect } from "react";
import WidgetBot from '@widgetbot/react-embed'
import WebinarJamButton from "./Register.tsx";
import WistiaPlayer from "./Video.tsx";

export const Button = ({ className = "", ...props }) => (
  <button
    className={`px-6 py-3 bg-green-500 text-black text-base md:text-lg font-semibold rounded-lg hover:bg-green-600 transition ${className}`}
    {...props}
  />
);

export const Card = ({ className = "", children, ...props }) => (
  <div className={`bg-black p-4 rounded-lg shadow-md ${className}`} {...props}>
    {children}
  </div>
);

export const CardContent = ({ className = "", children, ...props }) => (
  <div className={`p-2 ${className}`} {...props}>
    {children}
  </div>
);

const Webinar = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [formattedDate, setFormattedDate] = useState<string | null>(null);
  const dateParam = urlParams.get('date');
  const calculateTimeLeft = () => {
    const targetDate = dateParam ? new Date(dateParam).getTime() : null;
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    if (dateParam) {
      const date = new Date(dateParam);
      // Format the date into something like "March 20, 7 PM EST"
      const options: Intl.DateTimeFormatOptions = {
        timeZone: "America/New_York",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
        timeZoneName: "short",
      };
      const formatted = date.toLocaleString("en-US", options);
      setFormattedDate(formatted);
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="min-h-screen bg-black text-green-500 flex flex-col items-center p-4 md:p-8 text-center">
      <img src="/D.png" alt="Founder" className="w-1/3 md:w-1/6 rounded-lg mb-4" />

      <h2 className="mt-4 text-white text-2xl md:text-4xl font-bold">
        Discover The Supply & Demand Strategy<br />
        That Made Me <span className="text-green-500">Multiple 7 Figures</span> <br />
        Without Previous Experience Or <span className="text-green-500">Knowledge</span>
      </h2>

      <h3 className="mt-4 text-sm md:text-lg font-semibold italic mb-2">
        Master The Supply & Demand Strategy That's Designed <br />
        To Generate Consistent Profits On Repeat
      </h3>

      <WistiaPlayer />

      <div className="flex flex-col items-center bg-black text-green-500 p-4 md:p-8">
            <h1 className="text-lg md:text-2xl font-bold text-white">
      Free Webinar @ {formattedDate ?? "Loading..."}
    </h1>

        <div className="mt-4 grid grid-cols-2 md:grid-cols-4 gap-4 text-xs md:text-sm font-semibold border border-green-500 rounded-lg p-2">
          {Object.entries(timeLeft).map(([unit, value]) => (
            <div key={unit} className="p-2 rounded-lg">
              <span className="block text-3xl md:text-5xl">{value}</span>
              {unit.charAt(0).toUpperCase() + unit.slice(1)}
            </div>
          ))}
        </div>
      </div>

      <WebinarJamButton />

      <div className="mt-6 max-w-3xl border border-green-500 p-4 md:p-6 rounded-lg shadow-lg text-white text-left">
        <h2 className="text-lg md:text-2xl font-bold text-green-500">On This FREE Live Workshop You'll Learn:</h2>
        <ul className="mt-4 space-y-2 text-sm md:text-lg">
          <li>✔ The Hidden Psychology of Market Movement Most Traders Never See</li>
          <li>✔ The 3 Laws Of Price Movement</li>
          <li>✔ How our Unique, Yet Simple Supply & Demand Strategy Works</li>
          <li>✔ How to Actually Spot the Evidence Of Institutional Buying And Selling</li>
          <li>✔ Learn Our 2 Trading Models Used Daily To Profit Consistently</li>
          <li>✔ Learn How To Apply Our Strategy And Get Consistent Prop Firm Payouts In 180 Days</li>
        </ul>
      </div>

      <div className="mt-6 flex flex-col md:flex-row max-w-3xl items-center text-left">
        <img src="/founder.jpg" alt="Founder" className="w-2/3 md:w-2/5 rounded-lg mb-4 md:mr-7" />
        <div>
          <h3 className="text-xl md:text-2xl font-bold underline">Your Host</h3>
          <p className="mt-2 text-white text-sm md:text-md">
            I'm Mathews Farias (your host) and at just 13 years old, I began my journey into the financial markets under the guidance of three phenomenal mentors - true veterans of Wall Street who had traded from and institutional standpoint in the Pits. They taught me something crucial: We as retails traders will never win over the Institutions, banks and hedge funds. The only way to success in the markets is by following them ! For the past 8 years, I've refined my supply & demand strategy through thousands of hours of backtesting, developing a simple rule based strategy that works not just for me - but for my students too.
          </p>
        </div>
      </div>

      <WebinarJamButton />

      <div className="mt-6 w-full">
        <h3 className="text-lg md:text-2xl font-bold underline mb-2">Don't Just Take My Word For It</h3>
        <h2 className="text-lg md:text-3xl font-bold underline mb-2 text-white">Check Out Some Of Our Reviews...</h2>
        <div className="rounded-lg w-full flex justify-center">
          <WidgetBot className="w-full md:w-1/2 h-[400px] md:h-[600px]" server="1247352959554158732" channel="1326545885315727390" />
        </div>
      </div>

      <div className="mt-6 w-full md:w-1/2 bg-black p-4 md:p-6 rounded-lg border border-green-500">
        <h2 className="text-lg md:text-xl font-bold">Join Now!</h2>
        <h3 className="text-md md:text-xl font-bold mt-2 text-white">For a limited time only, This workshop is 100% FREE!</h3>
        <WebinarJamButton />
      </div>
    </div>
  );
};

export default Webinar;
